import React from 'react';
import Button from '../Button';
import EmptyBagIcon from '../icons/EmptyStateBag.svg';
import AvailabilityPanel from './AvailabilityPanel';
import * as styles from './index.module.scss';

export default ({
  T,
  appStyles,
  branchAvailability = {},
  servingOptionTag,
  nextPath
}) => {
  const { branch = {}, availableFrom = null } = branchAvailability;

  return (
    <>
      <EmptyBagIcon
        className={styles.EmptyBagIcon}
        style={{ color: appStyles.accentColor }}
      />
      <h2 style={{ textAlign: "center", color: appStyles.accentColor }}>
        {branch.name}
      </h2>
      <AvailabilityPanel
        T={T}
        appStyles={appStyles}
        disableOrder
        availableFrom={availableFrom}
        timeZoneStr={branch.timeZoneStr}
        servingOptionTag={servingOptionTag}
      />
      <Button
        appStyles={appStyles}
        centered
        style={{ marginTop: 16 }}
        to={nextPath}
      >
        {T("See the menu anyway")}
      </Button>
    </>
  );
};
